import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, DivideTitle } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { MDBDataTable } from "mdbreact";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/joy/Stack";

import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";
import parse from "html-react-parser";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Notifications() {
  const [openWarning, setOpenWarning] = useState(false);

  const [selectedNotification, setSelectedNotification] = useState("");

  const [value, setValue] = useState(dayjs(new Date()));

  const [notifications, setNotifications] = useState([]);

  const [callApi, setCallApi] = useState(false);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const changeStatus = async (notification) => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}notification/toggle-status/${notification._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const deleteNotification = async (notification) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}notification/delete-notification/${notification._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const fetchNotifications = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}notification/get-all-notifications`
      )
      .then((res) => {
        console.log(res.data);
        setNotifications(res.data);
      })
      .catch((e) => {
        setNotifications([]);
      });
  };

  useEffect(() => {
    fetchNotifications();
  }, [callApi]);

  const data = {
    columns: [
      {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 270,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 270,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: notifications?.map((notification) => {
      return {
        title: notification.name,
        description: parse(notification.description),
        board: notification.board ? notification.board.name : "N/A",
        grade: notification.grade ? notification.grade.name : "N/A",
        date: moment(notification.date).format("DD/MM/YYYY"),
        time: moment(notification.time).format("HH:mm A"),
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedNotification(notification);
                setWarning({
                  text: `Do you want to change the status to ${
                    notification.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                notification.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {notification.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the notification?`,
                  type: "delete",
                });
                setSelectedNotification(notification);
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Manage Notifications"}></Breadcrumb>
            <Link to="/add-notifications">
              <Button className="btn btn-primary">Add Notifications</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Manage Notifications" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={data}
                          style={{ marginTop: "1rem" }}
                        />
                        {/* <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          S.no
                                        </th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">&nbsp; 1</th>
                                        <td>
                                        Notification 1
                                        </td>
                                        <td>Notification Description</td>
                                        <td>30/12/2022</td>
                                        <td>01:00pm</td>
                                    
                                        
                                        <td style={{display:"flex",justifyContent:"space-evenly",width:"100%"}}>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"0.5rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">&nbsp; 2</th>
                                        <td>
                                        Notification 2
                                        </td>
                                        <td>Notification Description</td>
                                        <td>30/12/2022</td>
                                        <td>01:00pm</td>
                                    
                                        
                                        <td style={{display:"flex",justifyContent:"space-evenly",width:"100%"}}>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"0.5rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table> */}
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedNotification);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteNotification(selectedNotification);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
