import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";

import TextField from "@mui/material/TextField";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button } from "../../components/elements";

export default function AddQualification() {
  const navigate = useNavigate();
  const [streams, setStreams] = useState("");
  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const addStreams = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}streams/add-streams`, {
        streams,
      })
      .then((res) => {
        Success("Streams Added Successfully !");
        navigate("/streams-list");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Counselling Stream"}></Breadcrumb>
            <Link to="/streams-list">
              <Button className="btn btn-primary">
                View Counselling Stream
              </Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={3}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Streams Name" />
                  <TextField
                    id="outlined-required"
                    value={streams}
                    placeholder="Enter Streams Name"
                    sx={{ width: "100%" }}
                    onChange={(e) => setStreams(e.target.value)}
                  />
                </div>
              </Col>

              <Col xl={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5.25rem",
                  }}
                >
                  <Button className="btn btn-primary" onClick={addStreams}>
                    Add
                  </Button>
                </div>
              </Col>
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
