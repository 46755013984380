import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/ecommerce.json";
import { Box, Item, Anchor } from "../../components/elements";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ClientsCard,
  ActivityCard,
  OrdersCard,
} from "../../components/cards";
import { Navigate, useNavigate } from "react-router-dom";
import Axios from "axios";

export default function AdminDashboard() {
  const token = localStorage.getItem("token");
  const [menuData, setMenuData] = useState([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const fetchStudentsList = async () => {
    setLoading(true);
    await Axios.get(
      `${process.env.REACT_APP_BASE_URL}student/get-all-students`,
      { headers: { jwt: token } }
    )
      .then((res) => {})
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        //   alert("Session Expired !");
        navigate("/login");
        localStorage.clear();
      });
  };

  const fetchDashboardData = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}test/dashboard`;
    await Axios.get(url, { headers: { jwt: token } })
      .then(({ data }) => {
        setMenuData(data.responseData);
      })
      .catch((e) => {
        localStorage.clear();
        navigate("/login");
      });
  };

  useEffect(() => {
    fetchStudentsList();
    fetchDashboardData();
  }, []);

  return (
    <>
      {token ? (
        <>
          <PageLayout>
            <Row>
              <Col xl={12}>
                <Box className="mc-card">
                  <Breadcrumb title={data?.pageTitle}>
                    {data?.breadcrumb?.map((item, index) => (
                      <Item key={index} className="mc-breadcrumb-item">
                        {item.path ? (
                          <Anchor
                            className="mc-breadcrumb-link"
                            href={item.path}
                          >
                            {item.text}
                          </Anchor>
                        ) : (
                          item.text
                        )}
                      </Item>
                    ))}
                  </Breadcrumb>
                </Box>
              </Col>
              <Col xs={12} xl={12}>
                <Row xs={1} sm={2}>
                  {menuData?.map((item, index) => (
                    <Col key={index}>
                      <EcommerceCard
                        icon={item.icon}
                        trend={item.trend}
                        title={item.title}
                        number={item.number}
                        variant={item.variant}
                        href={item.href}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </PageLayout>
        </>
      ) : (
        <Navigate to="/login" replace={true} />
      )}
    </>
  );
}
