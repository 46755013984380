import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { Button } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
export default function QualificationList() {
  const [openEditStream, setOpenEditStream] = useState(false);
  const [editedStream, setEditedditedStream] = useState("");

  const [selectedStream, setSelectedStream] = useState({
    name: "",
    id: "",
  });
  const [SelectedStreamActive, setSelectedStreamActive] = useState(0);

  const [Stream, setStream] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);

  const changeStatus = async (board_id, active) => {
    let FinalStatus;
    if (active === 0) {
      FinalStatus = 1;
    } else {
      FinalStatus = 0;
    }
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}streams/change-status`, {
        id: board_id,
        active: FinalStatus,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const editStreams = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}streams/edit-streams/${selectedStream.id}`,
        { streams: editedStream }
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .finally(() => {
        setOpenEditStream(false);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const deleteStreams = async (board_id, streams) => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}streams/delete-streams`, {
        id: board_id,
        streams,
      })
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const fetchStreams = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}streams/get-all-streams`)
      .then((res) => {
        setStream(res.data.Streams);
      })
      .catch((e) => {
        setStream([]);
      });
  };

  useEffect(() => {
    fetchStreams();
  }, [callApi]);

  const qualification_data = {
    columns: [
      {
        label: "S.no",
        field: "s_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Stream Name",
        field: "streams_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: Stream?.map((Stream, index) => {
      return {
        s_no: index + 1,
        streams_name: Stream.name,
        actions: (
          <td
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              type="button"
              onClick={() => {
                setOpenEditStream(true);
                setEditedditedStream(Stream.name);
                setSelectedStream({
                  name: Stream.name,
                  id: Stream._id,
                });
              }}
              style={{
                width: "2rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className="btn btn-success"
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedStream({ id: Stream._id });
                setSelectedStreamActive(Stream.active);
                setWarning({
                  text: `Do you want to change the status to ${
                    Stream.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                Stream.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {Stream.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the Stream?`,
                  type: "delete",
                });
                setSelectedStream({
                  name: Stream.name,
                  id: Stream._id,
                });
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Breadcrumb title={"Counselling Stream List"}></Breadcrumb>
            <Link to="/add-streams">
              <Button>ADD Counselling Stream</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Counselling Stream List" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={qualification_data}
                          style={{ marginTop: "1rem" }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        {/* Edit Stream Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openEditStream}
          onClose={() => setOpenEditStream(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              Edit Stream
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter Stream ..."
                multiline
                label="Stream"
                value={editedStream}
                onChange={(e) => setEditedditedStream(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                disabled={editedStream ? false : true}
                onClick={editStreams}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>

        {/* Warning Stream Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "30%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedStream.id, SelectedStreamActive);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteStreams(selectedStream.id, selectedStream.name);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
