import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

// import MyCKeditor from "../../components/editor/MyCKeditor";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";

import CKEditorComponent from "../../components/CKEditorComponent";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoaderProvider } from "../../context/Preloader";

export default function EditQuestion() {
  const navigate = useNavigate();

  const location = useLocation();

  const [boards, setBoards] = useState("");
  const [grades, setGrades] = useState("");
  const [subjects, setSubjects] = useState("");
  const [chapters, setChapters] = useState("");

  const [loading, setLoading] = useState(false);

  const Question = location.state.Question;

  const [question, setQuestion] = useState({
    board: Question.board,
    grade: Question.grade,
    subject: Question.subject,
    chapter: Question.chapter,
    question: Question.question,
    explanation: Question.explanation,
    videoUrl: Question.videoUrl,
  });

  const [options, setOptions] = useState(Question.options);

  const handleOptionChange = (e, index, data) => {
    const updatedOptions = [...options];
    updatedOptions[index].options.option = data;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { option: "", correct: 0 }]);
  };

  const handleCorrectOptionChange = (index) => {
    const updatedOptions = [...options];
    updatedOptions.forEach((option, i) => {
      option.options.correct = i === index ? 1 : 0;
    });
    setOptions(updatedOptions);
  };

  const Success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const fetchBoards = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        setBoards([]);
      });
  };

  const fetchGrades = async () => {
    if (question.board) {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}grade/get-grades-by-board/${question.board}`
        )
        .then((res) => {
          setGrades(res.data.grades);
        })
        .catch((e) => {
          setGrades([]);
        });
    }
  };

  const fetchSubjects = async () => {
    if (question.board && question.grade) {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}subject/subjects?board=${question.board}&grade=${question.grade}`
        )
        .then((res) => {
          setSubjects(res.data.subjects);
        })
        .catch((e) => {
          setSubjects([]);
        });
    }
  };

  const fetchChapters = async () => {
    if (question.board && question.grade && question.subject) {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}chapter/get-chapters-filter?board=${question.board}&grade=${question.grade}&subject=${question.subject}`
        )
        .then((res) => {
          setChapters(res.data.chapters);
        })
        .catch((e) => {
          setChapters([]);
        });
    }
  };

  const editQuestion = async () => {
    setQuestion({ ...question, options: options });
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}question/questions/${Question._id}`,
        {
          board: question.board,
          grade: question.grade,
          subject: question.subject,
          chapter: question.chapter,
          question: question.question,
          options: options,
          explanation: question.explanation,
          videoUrl: question.videoUrl,
        }
      )
      .then((res) => {
        Success("Question Edited Successfully !");
        navigate("/question-bank");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  useEffect(() => {
    fetchBoards();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, [question.board]);

  useEffect(() => {
    fetchSubjects();
  }, [question.board, question.grade]);

  useEffect(() => {
    fetchChapters();
  }, [question.board, question.grade, question.subject]);

  return (
    <>
      {!loading ? (
        <>
          <PageLayout>
            <ToastContainer autoClose={1000} />
            <Row>
              <Col xl={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Breadcrumb title={"Edit Question"}></Breadcrumb>
                  <Link to="/question-bank">
                    <Button className="btn btn-primary">View Questions</Button>
                  </Link>
                </div>
              </Col>
              <Col xl={12}>
                <Box className="mc-card">
                  <Row xs={1} md={1} xl={1}>
                    <Col xl={3}>
                      <CardHeader title="Board Name" />
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Board
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Board"
                          value={question.board}
                          onChange={(e) =>
                            setQuestion({ ...question, board: e.target.value })
                          }
                        >
                          {boards?.length > 0 &&
                            boards.map((board) => {
                              return (
                                <MenuItem key={board._id} value={board._id}>
                                  {board.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>

                    <Col xl={3}>
                      <CardHeader title="Grade" />
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Grade
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Grade"
                          value={question.grade}
                          disabled={question.board ? false : true}
                          onChange={(e) =>
                            setQuestion({ ...question, grade: e.target.value })
                          }
                        >
                          {grades?.length > 0 &&
                            grades.map((grade) => {
                              return (
                                <MenuItem key={grade._id} value={grade._id}>
                                  {grade.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>

                    <Col xl={3}>
                      <CardHeader title="Subject" />
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Subject
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Subject"
                          value={question.subject}
                          disabled={question.grade ? false : true}
                          onChange={(e) =>
                            setQuestion({
                              ...question,
                              subject: e.target.value,
                            })
                          }
                        >
                          {subjects?.length > 0 &&
                            subjects.map((subject) => {
                              return (
                                <MenuItem key={subject._id} value={subject._id}>
                                  {subject.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>

                    <Col xl={3}>
                      <CardHeader title="Select Chapter" />
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Chapter
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Chapter"
                          value={question.chapter}
                          disabled={question.subject ? false : true}
                          onChange={(e) =>
                            setQuestion({
                              ...question,
                              chapter: e.target.value,
                            })
                          }
                        >
                          {chapters?.length > 0 &&
                            chapters.map((chapter) => {
                              return (
                                <MenuItem key={chapter._id} value={chapter._id}>
                                  {chapter.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Col>

                    <Col xl={12}>
                      {/* <DivideTitle title="medium size" className="mb-4 mt-3" /> */}
                      <Box className="mc-card">
                        <CardHeader title={"Add Question"} />
                        <div style={{ marginBottom: "3rem" }}>
                          <CKEditorComponent
                            uploadUrl="https://api.growsharp.in/upload"
                            data={question.question}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setQuestion({ ...question, question: data });
                            }}
                          />

                          {/* <CKEditor
                            editor={Editor}
                            data={question.question}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setQuestion({ ...question, question: data });
                            }}
                          /> */}
                        </div>

                        <div
                          style={{
                            width: "100%",
                            justifyContent: "end",
                            display: "flex",
                          }}
                        >
                          <span
                            className="btn btn-primary btn-circle"
                            onClick={handleAddOption}
                          >
                            <i className="fas fa-plus"></i>&nbsp;&nbsp;Add
                            Option
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "start",
                          }}
                        >
                          <div>
                            <CardHeader title={"Correct"} />
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue=""
                              name="radio-buttons-group"
                              sx={{ marginRight: "1.5rem" }}
                            >
                              {options.map((option, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={index.toString()}
                                  control={<Radio />}
                                  style={{
                                    marginLeft: "0",
                                    paddingLeft: "0",
                                    marginBottom: "8rem",
                                  }}
                                  label={`Option ${String.fromCharCode(
                                    65 + index
                                  )}`}
                                  labelPlacement="start"
                                  checked={option.options.correct}
                                  onChange={() =>
                                    handleCorrectOptionChange(index)
                                  }
                                />
                              ))}
                            </RadioGroup>
                          </div>
                          <div>
                            <CardHeader title={"Question Option"} />

                            {options.map((option, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{ marginBottom: "1.25rem" }}
                                >
                                  <CKEditorComponent
                                    uploadUrl="https://api.growsharp.in/upload"
                                    data={option.options.option}
                                    value={option.option}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      handleOptionChange(event, index, data);
                                    }}
                                  />

                                  {/* <CKEditor
                                    editor={Editor}
                                    data={option.options.option}
                                    onReady={(editor) => {}}
                                    value={option.option}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      handleOptionChange(event, index, data);
                                    }}
                                  /> */}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <Accordion sx={{ marginBottom: "2rem" }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Add Explanation</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/* <CKEditor
                              editor={Editor}
                              data={question.explanation}
                              onReady={(editor) => {
                                // You can store the "editor" and use when it is needed.
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setQuestion({ ...question, explanation: data });
                              }}
                            /> */}

                            <CKEditorComponent
                              uploadUrl="https://api.growsharp.in/upload"
                              data={question.explanation}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setQuestion({ ...question, explanation: data });
                              }}
                            />

                            <TextField
                              id="outlined-required"
                              label="Video Solution Url"
                              defaultValue=""
                              value={question.videoUrl}
                              onChange={(e) =>
                                setQuestion({
                                  ...question,
                                  videoUrl: e.target.value,
                                })
                              }
                              sx={{
                                marginTop: "2rem",
                                width: "100%",
                                marginBottom: "1rem",
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            marginTop: "2rem",
                          }}
                        >
                          <Button
                            className="btn btn-primary mb-2"
                            onClick={(e) => {
                              e.preventDefault();
                              editQuestion();
                            }}
                          >
                            Submit Question
                          </Button>
                        </div>
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
          </PageLayout>
        </>
      ) : (
        <LoaderProvider loading={loading} />
      )}
    </>
  );
}
