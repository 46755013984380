import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Link, useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import CKEditorComponent from "../../components/CKEditorComponent";

export default function AddNotifications() {
  const navigate = useNavigate();
  const [boards, setBoards] = useState("");
  const [grades, setGrades] = useState("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    name: "",
    date: null,
    time: null,
    description: "",
    board: null,
    grade: null,
  });

  const handleChangeDate = (newValue) => {
    if (newValue.isValid()) {
      const isoFormattedDate = newValue.toISOString(); // Convert the time to ISO 8000 format
      setNotification({ ...notification, date: isoFormattedDate });
    }
  };

  const handleChangeTime = (newValue) => {
    if (newValue.isValid()) {
      const isoFormattedTime = newValue.toISOString(); // Convert the time to ISO 8000 format
      setNotification({ ...notification, time: isoFormattedTime });
    }
  };
  const currentDate = dayjs();

  const fetchBoards = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}board/get-all-boards`)
      .then((res) => {
        setBoards(res.data.Boards);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        setBoards([]);
      });
  };

  const fetchGrades = async () => {
    if (notification.board) {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}grade/get-grades-by-board/${notification.board}`
        )
        .then((res) => {
          setGrades(res.data.grades);
        })
        .catch((e) => {
          setGrades([]);
        });
    }
  };

  useEffect(() => {
    fetchBoards();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, [notification.board]);

  const addNotification = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}notification/add-notification`,
        notification
      )
      .then((res) => {
        navigate("/notifications");
      })
      .catch((e) => {
        Error(e.response.data.message);
      });
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={"Add Notifications"}></Breadcrumb>
            <Link to="/notifications">
              <Button className="btn btn-primary">Notifications List</Button>
            </Link>
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <CardHeader title="Title" />
                <TextField
                  id="outlined-required"
                  defaultValue=""
                  value={notification.name}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setNotification({ ...notification, name: e.target.value })
                  }
                />
              </Col>

              <Col xl={2}>
                <CardHeader title="Date" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    value={notification.date}
                    onChange={handleChangeDate}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={currentDate}
                  />
                </LocalizationProvider>
              </Col>

              <Col xl={2}>
                <CardHeader title="Time" />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={notification.time}
                    onChange={handleChangeTime}
                    placeholder="HH:MM"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Col>

              <Col xl={3}>
                <div>
                  <CardHeader title="Board Name" />
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Board</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Board"
                      value={notification.board}
                      onChange={(e) =>
                        setNotification({
                          ...notification,
                          board: e.target.value,
                        })
                      }
                    >
                      {boards.length > 0 &&
                        boards.map((board) => {
                          return (
                            <MenuItem key={board._id} value={board._id}>
                              {board.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={3}>
                <div>
                  <CardHeader title="Grade" />
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Grade"
                      value={notification.grade}
                      disabled={notification.board ? false : true}
                      onChange={(e) =>
                        setNotification({
                          ...notification,
                          grade: e.target.value,
                        })
                      }
                    >
                      {grades.length > 0 &&
                        grades.map((grade) => {
                          return (
                            <MenuItem key={grade._id} value={grade._id}>
                              {grade.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>
              <Col xl={9}>
                <CardHeader title="Description" />
                <CKEditorComponent
                  uploadUrl="https://api.growsharp.in/upload"
                  data={notification.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setNotification({
                      ...notification,
                      description: data,
                    });
                    // console.log(data);
                    // setQuestion({ ...question, question:  });
                  }}
                />
                {/* <TextField
                  id="outlined-textarea"
                  placeholder="Enter Notification Description..."
                  multiline
                  value={}
                  sx={{ width: "100%" }}
                  onChange={(e) =>
                    setNotification({
                      ...notification,
                      description: e.target.value,
                    })
                  }
                /> */}
              </Col>

              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "3.25rem",
                  }}
                >
                  <Link to="/sessions-list">
                    <Button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        addNotification();
                      }}
                    >
                      Add
                    </Button>
                  </Link>
                </div>
              </Col>

              <Col xl={12} />
            </Row>
          </Box>
        </Col>
      </Row>
    </PageLayout>
  );
}
