import React from "react";
import DotsMenu from "../DotsMenu";
import { Box, Icon, Text, Heading } from "../elements";
import { Link } from "react-router-dom";

export default function EcommerceCard({
  variant,
  trend,
  number,
  title,
  icon,
  href,
}) {
  return (
    <Box className={`mc-ecommerce-card ${variant}`}>
      <Icon className="mc-ecommerce-card-trend material-icons">{trend}</Icon>
      <Box className="mc-ecommerce-card-head">
        <Link to={href}>
          <Heading as="h4" className="mc-ecommerce-card-meta">
            <Text as="span">{title}</Text>
            {number}
          </Heading>
        </Link>
        <Icon className="mc-ecommerce-card-icon material-icons">{icon}</Icon>
      </Box>
    </Box>
  );
}
