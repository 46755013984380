import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Modal, ModalClose, Sheet, Stack, Typography } from "@mui/joy";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

export default function OneToOne() {
  const [openViewStudent, setOpenViewStudent] = useState(false);

  const [allCounsellings, setAllCounsellings] = useState([]);
  const [grades, setGrades] = useState([]);

  const [aspirations, setAspirations] = useState([]);

  const [monthDojs, setmonthDojs] = useState([]);

  const [monthDoes, setmonthDoes] = useState([]);

  const [yearDojs, setyearDojs] = useState([]);

  const [yearDoes, setyearDoes] = useState([]);

  const [aspiration, setAspiration] = useState("");

  const [grade, setGrade] = useState("");

  const handleJoiningMonthChange = (event) => {
    const {
      target: { value },
    } = event;
    setmonthDojs(typeof value === "string" ? value.split(",") : value);
  };

  const handleExipireMonthChange = (event) => {
    const {
      target: { value },
    } = event;
    setmonthDoes(typeof value === "string" ? value.split(",") : value);
  };

  const handleJoiningYearChange = (event) => {
    const {
      target: { value },
    } = event;
    setyearDojs(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleExipireYearChange = (event) => {
    const {
      target: { value },
    } = event;
    setyearDoes(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // const [filters , setFilters] = useState({
  //   monthDojs : [],
  //   yearDojs : [],
  //   monthDoes : [],
  //   yearDoes : [],
  //   aspirant : "",
  //   grade : ""
  // })

  const months_data = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const years_data = ["2021", "2022", "2023", "2024"];

  const student_data = {
    columns: [
      {
        label: "First Name",
        field: "first_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Last Name",
        field: "last_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Board",
        field: "board",
        sort: "asc",
        width: 100,
      },
      {
        label: "School",
        field: "school",
        sort: "asc",
        width: 150,
      },
      {
        label: "Aspirant",
        field: "aspirant",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "District",
        field: "district",
        sort: "asc",
        width: 100,
      },
      {
        label: "Grade",
        field: "grade",
        sort: "asc",
        width: 100,
      },
      {
        label: "Counselling Type",
        field: "counselling_type",
        sort: "asc",
        width: 100,
      },
      {
        label: "DOJ",
        field: "doj",
        sort: "asc",
        width: 100,
      },
      {
        label: "DOE",
        field: "doe",
        sort: "asc",
        width: 100,
      },
      {
        label: "Add Session",
        field: "add_session",
        sort: "asc",
        width: 100,
      },
      {
        label: "View Session",
        field: "view_session",
        sort: "asc",
        width: 100,
      },
    ],
    rows: allCounsellings?.map((counselling) => {
      return {
        first_name: counselling.user.first_name,
        last_name: counselling.user.last_name,
        board: counselling.user.board,
        school: counselling.user.school,
        phone: counselling.user.mobile,
        aspirant: counselling.user.aspirant,
        state: counselling.user.state,
        district: counselling.user.district,
        grade: counselling.user.grade,
        counselling_type: "One To One",
        doj: moment(counselling.user.doj).format("DD-MM-YYYY"),
        doe: moment(counselling.user.doe).format("DD-MM-YYYY"),
        add_session: (
          <Link
            to="/add-session"
            state={{ type: "one", studentId: counselling.user._id,aspirant:counselling.user.aspirant }}
          >
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
              className="btn btn-primary"
            >
              Assign
            </button>
          </Link>
        ),
        view_session: (
          <Link
            to={`/sessions-list?studentId=${counselling.user._id}&type=one`}
          >
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
              className="btn btn-primary"
            >
              View
            </button>
          </Link>
        ),
      };
    }),
  };

  const getAllCounsellings = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}package/get-one-to-one-students`)
      .then((res) => {
        setAllCounsellings(res.data.students);
        console.log(res.data.students);
        const uniqueAspirations = [
          ...new Set(res.data.students.flatMap((item) => item.user.aspirant)),
        ];
        setAspirations(uniqueAspirations);
      })
      .catch((e) => {});
  };

  const fetchGrades = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}grade/get-all-grades`)
      .then((res) => {
        setGrades(res.data.Grades);
      })
      .catch((e) => {
        setGrades([]);
      });
  };

  const getCounsellings = async () => {
    const requestBody = {};

    if (monthDojs.length > 0) {
      requestBody.monthDojs = monthDojs;
    }

    if (yearDojs.length > 0) {
      requestBody.yearDojs = yearDojs;
    }

    if (monthDoes.length > 0) {
      requestBody.monthDoes = monthDoes;
    }

    if (yearDoes.length > 0) {
      requestBody.yearDoes = yearDoes;
    }

    if (aspiration) {
      requestBody.aspirant = aspiration;
    }

    if (grade) {
      requestBody.grade = grade;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}package/filter-counsellings`,
        requestBody
      );
      setAllCounsellings(response.data);
      const uniqueAspirations = [
        ...new Set(
          response.data.students.flatMap((item) => item.user.aspirant)
        ),
      ];
      setAspirations(uniqueAspirations);
    } catch (error) {}
  };

  useEffect(() => {
    getAllCounsellings();
  }, []);

  useEffect(() => {
    getCounsellings();
  }, []);

  useEffect(() => {
    fetchGrades();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Breadcrumb title={"One to One Students List"}></Breadcrumb>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Month DOJ" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={monthDojs}
                      onChange={handleJoiningMonthChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {months_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={monthDojs.includes(name)} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Year DOJ" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={yearDojs}
                      onChange={handleJoiningYearChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {years_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={yearDojs.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Month DOE" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={monthDoes}
                      onChange={handleExipireMonthChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {months_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={monthDoes.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Year DOE" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={yearDoes}
                      onChange={handleExipireYearChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {years_data.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={yearDoes.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Aspiration" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={aspiration}
                      onChange={(e) => setAspiration(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {aspirations.length > 0 &&
                        aspirations.map((aspiration) => {
                          return (
                            <MenuItem value={aspiration}>{aspiration}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Grade" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {grades.length > 0 &&
                        grades.map((grade) => {
                          return (
                            <MenuItem key={grade._id} value={grade._id}>
                              {grade.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Board" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={grade}
                      onChange={(e) => setGrade(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {grades.length > 0 &&
                        grades.map((grade) => {
                          return (
                            <MenuItem key={grade._id} value={grade._id}>
                              {grade.board.name}-{grade.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={2}>
                <div style={{ marginTop: "2rem" }}>
                  <CardHeader title="Select Aspirant" />
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={aspirations}
                      onChange={(e) => setAspirations(e.target.value)}
                    >
                      <MenuItem value="">None</MenuItem>
                      {grades.length > 0 &&
                        grades.map((grade) => {
                          return (
                            <MenuItem key={grade._id} value={grade._id}>
                              {grade.board.name}-{grade.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
              </Col>

              <Col xl={10}></Col>
              <Col xl={2}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      getCounsellings();
                    }}
                    className="btn btn-primary"
                  >
                    Apply
                  </Button>
                </div>
              </Col>

              <Col xl={12}>
                <Box className="mc-card">
                  <div className="container" style={{ marginTop: "1rem" }}>
                    <div
                      className="row"
                      style={{ justifyContent: "end", width: "100%" }}
                    ></div>
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={student_data}
                          style={{
                            marginTop: "1rem",
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        />
                        {/* <table className="table table-bordered" style={{ display: "block", overflowX: "auto", whiteSpace: "nowrap"}}>
                                    <thead>
                                      <tr>
                                        <th scope="col">S.no</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Board</th>
                                        <th scope="col">School</th>
                                        <th scope="col">Aspirant</th>
                                        <th scope="col">State</th>
                                        <th scope="col">District</th>
                                        <th scope="col">Grade</th>
                                        <th scope="col">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">&nbsp; 1</th>
                                        
                                        <td>
                                        Swapnil
                                        </td>

                                        <td>
                                        Chatterjee
                                        </td>

                                        <td>
                                        6290345324
                                        </td>

                                        <td>
                                        CBSE
                                        </td>

                                        <td>
                                        Don Bosco
                                        </td>

                                        <td>
                                        Engineer
                                        </td>

                                        <td>
                                        Delhi
                                        </td>

                                        <td>
                                        New Delhi
                                        </td>

                                        <td>
                                        5th 
                                        </td>

                                     
                                       
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                        <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-primary"><i className="fas fa-eye"></i></button>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                          
                                        </td>
                                      </tr>
                                      <tr>
                                        <th scope="row">&nbsp; 2</th>
                                        
                                        <td>
                                        Sunil
                                        </td>

                                        <td>
                                        Chatterjee
                                        </td>

                                        <td>
                                        6290345322
                                        </td>

                                        <td>
                                        ICSE
                                        </td>

                                        <td>
                                        Don Bosco
                                        </td>

                                        <td>
                                        Medical
                                        </td>

                                        <td>
                                        Delhi
                                        </td>

                                        <td>
                                        New Delhi
                                        </td>

                                        <td>
                                        6th 
                                        </td>
                                   
                                       
                                        <td style={{display:"flex",justifyContent:"start"}}>
                                        <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-primary"><i className="fas fa-eye"></i></button>
                                          <button type="button" style={{width:"2rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-success"><i className="fas fa-edit"></i></button>
                                          <button type="button" style={{width:"4rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center",marginRight:"1.6rem"}} className="btn btn-danger">Inactive</button>
                                          <button type="button" style={{width:"2rem",fontSize:"0.6rem",display:"flex",justifyContent:"center",alignItems:"center"}} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                                          
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table> */}
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
              {/*
                            <Col>
                                <DivideTitle title="large size" className="mb-4 mt-3" />
                                <LabelField label="text field" type="text" fieldSize="w-100 h-lg" labelDir="label-col mb-4" />
                                <LabelField label="select option" fieldSize="w-100 h-lg" labelDir="label-col mb-4" option={["admin", "member", "client"]} />
                                <LabelTextarea label="long textarea" fieldSize="w-100 h-text-lg" labelDir="label-col"  />
                            </Col> */}
            </Row>
          </Box>
        </Col>

        {/* View Student Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openViewStudent}
          onClose={() => setOpenViewStudent(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "50%",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Add Feedback
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <CardHeader title="Feedback" />
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter your feedback ..."
                multiline
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
