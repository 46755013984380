import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import { CardHeader } from "../../components/cards";
import { Box, Button } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { MDBDataTable } from "mdbreact";

import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

import Stack from "@mui/joy/Stack";

import TextField from "@mui/material/TextField";

import { Link } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import moment from "moment";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function FreeCareerTalkList() {
  const [openViewStudent, setOpenViewStudent] = useState(false);

  const [feedback, setFeedBack] = useState("");

  const [searchParams] = useSearchParams();

  const Type = searchParams.get("type");

  const [sessions, setSessions] = useState([]);

  const [selectedSession, setSelectedSession] = useState("");

  const [callApi, setCallApi] = useState(false);

  const [warning, setWarning] = useState({
    text: "",
    status: "",
  });

  const [openWarning, setOpenWarning] = useState(false);
  const Error = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const fetchCounselling = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}career/get-all-career-talks-admin`)
      .then((res) => {
        setSessions(res.data.careerTalks);
      })
      .catch((e) => {
        Error(e.response.data.message);
        setSessions([]);
      });
  };

  const addFeedback = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}career/edit-career-talk/${selectedSession._id}`,
        {
          name: selectedSession.name,
          aspirant: selectedSession.aspirant,
          link: selectedSession.link,
          password: selectedSession.password,
          date: selectedSession.date,
          purpose: selectedSession.purpose,
          counsellorId: selectedSession.counsellor._id,
          time: selectedSession.time,
          feedback: feedback,
        }
      )
      .then((res) => {
        setOpenViewStudent(false);
        setCallApi(!callApi);
      })
      .catch((e) => {
        Error(e.response.data.message);
        setSessions([]);
      });
  };

  const changeStatus = async (session) => {
    await axios
      .put(
        `${process.env.REACT_APP_BASE_URL}career/toggle-active/${session._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const deleteSession = async (session) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}career/delete-career-talk/${session._id}`
      )
      .then((res) => {
        setCallApi(!callApi);
      })
      .catch((e) => {});
  };

  const session_data = {
    columns: [
      {
        label: "Session Name",
        field: "session_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Counsellor",
        field: "counsellor",
        sort: "asc",
        width: 270,
      },
      {
        label: "Aspirant",
        field: "aspirant",
        sort: "asc",
        width: 200,
      },
      {
        label: "Purpose",
        field: "purpose",
        sort: "asc",
        width: 100,
      },
      {
        label: "Session Link",
        field: "session_link",
        sort: "asc",
        width: 150,
      },
      {
        label: "Password",
        field: "password",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Feedback",
        field: "feedback",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: sessions?.map((session) => {
      return {
        session_name: session.name,
        counsellor: session.counsellor.name,
        aspirant: session.aspirant.name,
        purpose: session.purpose,
        session_link: session.link,
        password: session.password,
        date: moment(session.date).format("DD/MM/YYYY"),
        time: moment(session.time).local().format("hh:mm A"),
        feedback: (
          <>
            {session.feedback ? (
              session.feedback
            ) : (
              <button
                onClick={() => {
                  setOpenViewStudent(true);
                  setSelectedSession(session);
                }}
                type="button"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0.5rem 1rem",
                  margin: "0.5rem",
                }}
                className="btn btn-primary"
              >
                ADD
              </button>
            )}
          </>
        ),
        actions: (
          <td
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Link
              to="/edit-session"
              state={{ Session: session, type: "Free Career Talk" }}
            >
              <button
                type="button"
                style={{
                  width: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1.6rem",
                }}
                className="btn btn-success"
              >
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setSelectedSession(session);
                setWarning({
                  text: `Do you want to change the status to ${
                    session.active === 0 ? `Active?` : `Inactive?`
                  }`,
                  type: "status",
                });
              }}
              style={{
                width: "4rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.6rem",
              }}
              className={
                session.active === 0 ? `btn btn-danger` : `btn btn-success`
              }
            >
              {session.active === 0 ? `Inactive` : `Active`}
            </button>
            <button
              type="button"
              onClick={() => {
                setOpenWarning(true);
                setWarning({
                  text: `Do you want to delete the session?`,
                  type: "delete",
                });
                setSelectedSession(session);
              }}
              style={{
                width: "2rem",
                fontSize: "0.6rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i>
            </button>
          </td>
        ),
      };
    }),
  };

  useEffect(() => {
    fetchCounselling();
  }, [callApi]);

  return (
    <PageLayout>
      <ToastContainer autoClose={1000} />
      <Row>
        <Col xl={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Breadcrumb title={`Manage Sessions`}></Breadcrumb>
            <Link to={`/add-career-talk`}>
              <Button className="btn btn-primary">Add Session</Button>
            </Link>
            {Type == "one-to-one" && (
              <Link to="/one-to-one-list">
                <Button className="btn btn-primary">Counselling List</Button>
              </Link>
            )}
          </div>
        </Col>
        <Col xl={12}>
          <Box className="mc-card">
            <Row xs={1} md={1} xl={1}>
              <Col xl={12}>
                <Box className="mc-card">
                  <CardHeader title="Manage Sessions" />
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <MDBDataTable
                          striped
                          bordered
                          small
                          data={session_data}
                          style={{
                            marginTop: "1rem",
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Box>
        </Col>

        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          style={{ overflowY: "scroll" }}
          open={openViewStudent}
          onClose={() => setOpenViewStudent(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
            overflowY: "scroll",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "70%",
              height: "40rem",
              overflowY: "scroll",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={1}
            >
              Add Feedback
            </Typography>

            <Stack spacing={2} sx={{ alignItems: "center" }}>
              <CardHeader title="Feedback" />
              <TextField
                id="outlined-required"
                defaultValue=""
                placeholder="Enter your feedback ..."
                multiline
                onChange={(e) => setFeedBack(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Stack>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "2rem",
              }}
            >
              <button
                type="button"
                disabled={feedback ? false : true}
                onClick={(e) => {
                  e.preventDefault();
                  addFeedback();
                }}
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Sheet>
        </Modal>

        {/* Warning Board Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          style={{ overflowY: "scroll" }}
          open={openWarning}
          onClose={() => setOpenWarning(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
            overflowY: "scroll",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              width: "70%",
              height: "40rem",
              overflowY: "scroll",
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              bgcolor: "#fff",
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "#eee",
              }}
            />
            <Typography
              id="basic-modal-dialog-title"
              component="h2"
              level="inherit"
              fontSize="1.25em"
              mb={5}
            >
              {warning.text}
            </Typography>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (warning.type === "status") {
                    changeStatus(selectedSession);
                    setOpenWarning(false);
                  } else if (warning.type === "delete") {
                    deleteSession(selectedSession);
                    setOpenWarning(false);
                  }
                }}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-success"
              >
                Yes
              </button>
              <button
                onClick={() => setOpenWarning(false)}
                type="button"
                style={{
                  width: "8rem",
                  fontSize: "0.8rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                className="btn btn-danger"
              >
                No
              </button>
            </div>
          </Sheet>
        </Modal>
      </Row>
    </PageLayout>
  );
}
